


































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import DrawerComponent from '@common-src/mixins/drawer-component';
import { FacilityAttributeEntityModel, FacilityAttributeQueryModel } from '@common-src/entity-model/facility-attribute-entity';
import FacilityAttributeV2Service from '@common-src/service/facility-attribute-v2';
import { FacilityAttributeDataType } from '@common-src/model/enum';
import DeviceStatusDialog from '@common-src/pages3/dashboard/device/device-status-dialog.vue';
import { getDeviceId, getDeviceIdAndAttributeKey } from '@common-src/entity-model/device-entity';

@Component({
    components: {
        'device-status-dialog': DeviceStatusDialog
    }
})
export default class FacilityAttributeDrawer extends Mixins(TableDialogFormComponent, DrawerComponent) {
    FacilityAttributeEntityModel = FacilityAttributeEntityModel;
    FacilityAttributeDataType = FacilityAttributeDataType;
    saveBtnDisabled: boolean = true;
    facilityEntityId: string = null;
    facilityTypeId: string = null;

    init() {
        this.initTable({
            service: FacilityAttributeV2Service,
            queryModel: new FacilityAttributeQueryModel(this.facilityEntityId, false),
            tableColumns: FacilityAttributeEntityModel.getFacilityTabelColumns()
        });
    }

    drawAttributeOpen(facilityEntityId: string, drawerTitle: string, facilityTypeId?: string) {
        this.listData = null;
        this.facilityTypeId = facilityTypeId;
        this.facilityEntityId = facilityEntityId;
        this.drawerTitle = drawerTitle;
        this.drawerOpen(facilityEntityId);
        if (this.service) {
            if ((this.queryModel as FacilityAttributeQueryModel).entityId === facilityEntityId) {
                this.getList();
            } else {
                (this.queryModel as FacilityAttributeQueryModel).entityId = facilityEntityId;
            }
        } else {
            this.init();
            this.getList();
        }
    }

    saveClick() {
        return FacilityAttributeV2Service.saveFacilityAttribute(this.listData as any).then(res => {
            this.showMessageSuccess('保存设施属性成功');
            this.saveBtnDisabled = true;
        });
    }
    detailClick(record: FacilityAttributeEntityModel) {
        let selectOneValue = null;
        if (Array.isArray(record.dataValue)) {
            selectOneValue = _.get(record.dataValue, '[0]');
        } else {
            selectOneValue = record.dataValue;
        }
        if (selectOneValue) {
            if (record.type === FacilityAttributeDataType.DEVICE) {
                (this.$refs.deviceStatusDialog as DeviceStatusDialog).dialogOpen(selectOneValue);
            } else if ([FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(record.type) > -1) {
                const deviceId = getDeviceId(selectOneValue);
                const deviceAttributeKey = getDeviceIdAndAttributeKey(selectOneValue).attributeKey;
                (this.$refs.deviceStatusDialog as DeviceStatusDialog).dialogOpen(deviceId, deviceAttributeKey);
            }
        } else {
            if (record.type === FacilityAttributeDataType.DEVICE) {
                this.showMessageInfo('请先选择设备');
            } else if ([FacilityAttributeDataType.ATTRIBUTE, FacilityAttributeDataType.SERVER_ATTRIBUTE].indexOf(record.type) > -1) {
                this.showMessageInfo('请先选择设备属性');
            }
        }
    }

    getSaveBtnStatus(status) {
        this.saveBtnDisabled = status;
    }
}

