

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { FormControlType } from '@common-src/model/form-control';
import { ICRUDQ } from '@common-src/model/interface';
import { GroupEntityType, ViewModeType } from '@common-src/model/enum';
import FacilityV2Service from '@common-src/service/facility-v2';
import FacilityGroupService from '@common-src/service/facility-group';

@Component
export default class FacilityBatchGroupComponent extends FormDialogComponent<any> {
    private ids: Array<string>;
    dialogOpen(selectIds: any, service: ICRUDQ<any, any>, viewMode: ViewModeType, needGetDetail: boolean = false): void {
        this.ids = selectIds;
        this.dialogVisible = true;
        this.viewMode = viewMode;
        this.dialogTitle = '批量修改分组';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItem = {
                    key: 'groupId',
                    label: '分组',
                    type: FormControlType.TREE_SELECT,
                    optionsPromise: FacilityGroupService.getGroupTree,
                    optionsPromiseParam: GroupEntityType.FACILITY,
                    mode: 'multiple',
                    required: true
                };
                this.jtlForm.initFormByControls([controlItem]);
            }
        });
    }

    dialogOK(): Promise<any> {
        if (!this.jtlForm) {
            return;
        }
        return this.jtlForm.submitForm().then((ret) => {
            FacilityV2Service.batchChangeGroup(this.ids, this.jtlForm.formModel.groupId).then(res => {
                this.showMessageSuccess('批量修改设施分组成功');
                this.dialogClose();
                this.$emit('dialogOK');
            });
        }).catch(err => {
            throw err;
        });
    }
}
